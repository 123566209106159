import style from './Footer.module.scss';
import Link from 'next/link';
import * as ga from '../functions/GAEvents'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Footer() {

  return (
    <div className={style.Footer}>
      <nav>
        <h4>Quick Links</h4>
        <ul>
          {/* <li className={style.navLink}>
            <a href="https://www.basler.com/Employment/" onClick={() => ga.event('Careers Page')}>Careers</a>
          </li> */}
          <li className={style.navLink}>
            <Link href={'/contact-us'}><a>Contact Us</a></Link>
          </li>
          <li className={style.navLink}>
            <a href="https://www.basler.com/" onClick={() => ga.event('Basler Page')}>Basler Electric</a>
          </li>
          <li className={style.navLink}>
            <a href="https://www.basler.com/plastics" onClick={() => ga.event('Plastics Page')}>Basler Plastics</a>
          </li>
        </ul>
      </nav>
      <div className={style.socialContainer}>
        <h4>Stay Connected!</h4>
        <span className={style.social}><a aria-label="Facebook" href="https://www.facebook.com/baslerelectric/" onClick={() => ga.event('Facebook Page')}><FontAwesomeIcon icon={['fab', 'facebook-square']} /></a></span>
        <span className={style.social}><a aria-label="LinkedIn" href="https://www.linkedin.com/company/basler-electric-company" onClick={() => ga.event('LinkedIn Page')}><FontAwesomeIcon icon={['fab', 'linkedin']} /></a></span>
        <span className={style.social}><a aria-label="Youtube" href="https://www.youtube.com/channel/UCjxHSYhI_BEBiy-NeOElzxw" onClick={() => ga.event('Youtube Page')}><FontAwesomeIcon icon={['fab', 'youtube-square']} /></a></span>
      </div>
      <div className={`${style.infoContainer} ${style.hide}`}>
        <h4>Get In Touch</h4>
        <p>
          <span className={style.infoLabel}><FontAwesomeIcon icon={['fal', 'map-marker-alt']} /></span>
          <span>
            8115 Shaffer Parkway<br></br>
            Littleton, Colorado 80127
          </span>
        </p>
        <p>
          <span className={style.infoLabel}>Office</span>
          <span>(303) 988-6659</span>
        </p>
        <p>
          <span className={style.infoLabel}>Sales</span>
          <span>(303) 378-2244</span> 
        </p>
        <p>
          <span className={style.infoLabel}>Fax</span>
          <span>(303) 988-5714</span>
        </p>
      </div>
      <span className={style.copyright}>Copyright <FontAwesomeIcon icon={['fal', 'copyright']} /> Basler Services, LLC {Date.prototype.getFullYear}</span>
    </div>
  );
}

export default Footer;
